export default {

  apiKey: "AIzaSyDV4IQQgNb7udszYMzb60miROmz9079QIA",

  authDomain: "paradise-2y1b.firebaseapp.com",

  projectId: "paradise-2y1b",

  storageBucket: "paradise-2y1b.appspot.com",

  messagingSenderId: "1092765610032",

  appId: "1:1092765610032:web:d2549bbfa58212be88fdf5"

};
